import { SET_JOBS, SET_DIALOG } from "./types";
import request from "../utils/axios";

export const setJobs = (item) => ({ type: SET_JOBS, item: item });

export const setDialog = (item) => ({ type: SET_DIALOG, item: item });

export const getJobs = () => {
  return (dispatch, getState) => {
    dispatch(setJobs([]));
    // request
    //   .post("/index.php?r=api%2Fjob")
    //   .then((res) => {
    //     dispatch(setJobs(res.data));
    //   })
    //   .catch((err) => {});
  };
};
