import React from "react";
import "./NotFound.scss";
import E404 from "../../img/404.png";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="notFound">
      <Outlet />
      <div className="notFound__notice">
        <p className="notFound__notice__404">404</p>
        <p>{t("somethingMissing")}</p>
        <p>{t("thisPage")}</p>
        <button
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </button>
      </div>
      <img src={E404} alt="" />
    </div>
  );
}

export default NotFound;
