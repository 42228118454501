import React from "react";
import "./Legal.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TermsOfUse from "../Legal/TermsOfUse";
import PrivacyPolicy from "../Legal/PrivacyPolicy";
import Restricted from "../Legal/Restricted";
import { useTranslation } from "react-i18next";

function Legal() {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="legal">
      <div className="legal__title">
        <div className="legal__title__content">
          <h2>{t("legal")}</h2>
          <p>{t("legal-text")}</p>
        </div>
      </div>
      <Box className="legal__content">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} className="legal__content__title" variant="scrollable">
              <Tab label={t("terms.title")} value="1" />
              <Tab label={t("privacy.title")} value="2" />
              <Tab label={t("restricted.title")} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TermsOfUse />
          </TabPanel>
          <TabPanel value="2">
            <PrivacyPolicy />
          </TabPanel>
          <TabPanel value="3">
            <Restricted />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default Legal;
