import React from "react";
import "./Career.scss";
import JoinUsLogo from "../../../img/join_us.png";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Career(props) {
  const { t } = useTranslation();
  return (
    <div className="career">
      <div className="career__bg">
        <img src={JoinUsLogo} alt="" />
      </div>
      <div className="career__content">
        <div className="career__aboutUs">
          <h2>{t("aboutUs2")}</h2>
          <div className="career__aboutUs__text">
            <p>{t("whenItComesTo")}</p>
            <p>{t("whenItComesTo2")}</p>
          </div>
          <h1>{t("readyToHelp1")}</h1>
          <h1>
            <span>{t("readyToHelp2")}</span>
            {t("readyToHelp3")}
          </h1>
        </div>
        {/* <Outlet /> */}
      </div>
    </div>
  );
}

export default Career;
