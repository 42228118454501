import React, { useEffect } from "react";
import "./Home.scss";
import BackgroundVideo from "../../media/background.mp4";
import Features from "../Features";
import Button from "@mui/material/Button";
import SafePay from "../../img/safe_pay.svg";
import PaymentMethods from "../PaymentMethods";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
var Scroll = require("react-scroll");
var Element = Scroll.Element;
var scroller = Scroll.scroller;

function Home() {
  const { t } = useTranslation();
  const isMediaMatches = useMediaQuery("(min-width:900px)");
  const location = useLocation();
  const navigate = useNavigate();
  // console.log("location: >>> ", location);
  const scrollToTarget = (target) => {
    scroller.scrollTo(target, {
      duration: 500,
      // delay: 1000,
      smooth: true,
      // delay: 1000,
      offset: -80, // Scrolls to element + 50 pixels down the page
    });
  };
  useEffect(() => {
    console.log("location >> ", location);
    if (location.state === "features") {
      scrollToTarget("features");
    } else if (location.state === "payment-methods") {
      scrollToTarget("payment-methods");
    }
  }, [location]);
  return (
    <div className="home">
      <Outlet />
      <div className="home__background">
        <div className="home__background__text">
          <p>{t("paymentsFor")}</p>
          <p>{t("weOffer")}</p>
        </div>
        {isMediaMatches && (
          <video autoPlay loop muted className="home__backgroundVideo">
            <source src={BackgroundVideo} type="video/mp4" />
          </video>
        )}
      </div>
      <Element name="features">
        <Features />
      </Element>
      <div className="home__hiring">
        <div>
          <h2>{t("hiring")}</h2>
          <p>{t("startWorking")}</p>
          <Button
            variant="contained"
            onClick={() => {
              navigate("career");
            }}
          >
            {t("joinOur")}
          </Button>
        </div>
      </div>
      <div className="home__safety">
        <img src={SafePay} alt="" />
        <div className="home__safety__textContainer">
          <h2>{t("safelyAccept")}</h2>
          <p>{t("methodTo")}</p>
        </div>
      </div>
      <div className="home__contactUs">
        <div>
          <h2>{t("contactUs")}</h2>
          <p>{t("completeTheFields")}</p>
          <Button
            variant="contained"
            onClick={() => {
              navigate("contact");
            }}
          >
            {t("getStart")}
          </Button>
        </div>
      </div>
      <Element name="payment-methods">
        <PaymentMethods />
      </Element>
    </div>
  );
}

export default Home;
