import { AsYouType } from "libphonenumber-js";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";

const validatePhone = (number, code) => {
  code = String(code ?? "").toUpperCase();
  // if (code.length === 3) {
  //   code = countryCode3to2[code] ?? "";
  // }
  let asYouType = new AsYouType(code);
  asYouType.input(number);
  //console.log(number, countryCode3to2[code], asYouType.getNumber()?.country);
  return isValidPhoneNumber(number, code) && asYouType.getNumber()?.country === code;
};

export const checkPhone = ({ form, setForm, region, data }) => {
  let val = data === undefined ? form?.phone?.value : data;
  region = form?.phone?.countryObj?.alpha2_code || region;

  const validity = !!validatePhone(val, region);

  setForm((prev) => {
    return {
      ...prev,
      phone: { ...prev.phone, valid: validity, checked: true },
    };
  });

  return validity;
};

export const checkEmail = ({ form, setForm, data }) => {
  let val = data === undefined ? form?.email?.value : data;
  val = val ? String(val) : "";
  var format =
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g;

  const validity = format.test(val);

  setForm((prev) => {
    return {
      ...prev,
      email: { ...prev.email, valid: validity, checked: true },
    };
  });

  return validity;
};
