import React, { useEffect, useState } from "react";
import "./Apply.scss";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MobilePhone from "../../../form-components/MobilePhone";
import FormHelperText from "@mui/material/FormHelperText";
import { checkEmail, checkPhone } from "../../../../utils/validations";
import { getSign } from "../../../../utils/formats";
import { setDialog } from "../../../../redux/actions";
import request from "../../../../utils/axios";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
var Scroll = require("react-scroll");
var Element = Scroll.Element;
var scroller = Scroll.scroller;

function Apply(props) {
  const { t } = useTranslation();
  const englishLevel = [t("english-opt-1"), t("english-opt-2"), t("english-opt-3"), t("english-opt-4")];
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [targetJob, setTargetJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    first_name: { value: "", valid: true, max: 80, checked: false },
    last_name: { value: "", valid: true, max: 80, checked: false },
    email: { value: "", valid: true, max: 80, checked: false },
    phone: {
      value: "",
      valid: true,
      cCode: "",
      countryObj: "",
      checked: false,
    },
    city: { value: "", valid: true, max: 80, checked: false },
    why_luxpag: { value: "", valid: true, checked: false },
    experience: { value: "", valid: true, checked: false },
    linkedin: { value: "", valid: true, checked: false },
    company_contact_status: { value: "", valid: true, checked: false },
    company_contact: { value: "", valid: true, checked: false },
    english_level: { value: "", valid: true, checked: false },
    disability: { value: "", valid: true, checked: false },
  });
  const checkForm = (formKey) => {
    if (form[formKey]) {
      const validity = !!form[formKey].value;
      setForm((prev) => ({ ...prev, [formKey]: { ...prev[formKey], valid: validity, checked: true } }));
      return validity;
    }
    return false;
  };

  const handleError = () => {
    setIsLoading(false);
    props.setDialog({
      state: true,
      notice: {
        title: t("failed"),
        content: t("submit-error"),
      },
    });
  };
  const submitForm = () => {
    const validityFunctions = [
      checkForm("first_name"),
      checkForm("last_name"),
      checkEmail({ form: form, setForm: setForm }),
      checkPhone({ form: form, setForm: setForm }),
      checkForm("city"),
      checkForm("why_luxpag"),
      checkForm("experience"),
      checkForm("linkedin"),
      checkForm("company_contact_status"),
      checkForm("english_level"),
      checkForm("disability"),
    ];
    if (form.company_contact_status.value === "Yes") {
      validityFunctions.push(checkForm("company_contact"));
    }
    console.log("validityFunctions: ", validityFunctions);
    const validity = validityFunctions.every((item) => item === true);

    if (validity) {
      const reqData = {
        jobid: jobId,
        first_name: form.first_name.value,
        last_name: form.last_name.value,
        email: form.email.value,
        phone: form.phone.value,
        city: form.city.value,
        why_luxpag: form.why_luxpag.value,
        experience: form.experience.value,
        linkedin: form.linkedin.value,
        company_contact: form.company_contact_status.value === "Yes" ? form.company_contact.value : null,
        english_level: form.english_level.value,
        disability: form.disability.value,
        resume: null,
        letter: null,
      };
      const sign = getSign(reqData);
      const formData = new FormData();
      formData.append("jobid", reqData.jobid);
      formData.append("first_name", reqData.first_name);
      formData.append("last_name", reqData.last_name);
      formData.append("email", reqData.email);
      formData.append("phone", reqData.phone);
      formData.append("city", reqData.city);
      formData.append("why_luxpag", reqData.why_luxpag);
      formData.append("experience", reqData.experience);
      formData.append("linkedin", reqData.linkedin);
      formData.append("company_contact", reqData.company_contact);
      formData.append("english_level", reqData.english_level);
      formData.append("disability", reqData.disability);
      formData.append("resume", reqData.resume);
      formData.append("letter", reqData.letter);
      formData.append("sign", sign);
      // setIsLoading(true);
      // request
      //   .post("/index.php?r=api%2Fapplyfor", formData)
      //   .then((res) => {
      //     let response = res.data;
      //     if (Number(response.code) === 200) {
      //       setIsLoading(false);
      //       props.setDialog({
      //         state: true,
      //         notice: {
      //           title: t("apply-success-title"),
      //           content: t("apply-success"),
      //         },
      //         callback: () => {
      //           navigate("/career");
      //         },
      //       });
      //     } else {
      //       handleError();
      //     }
      //   })
      //   .catch(() => {
      //     handleError();
      //   });
    }
  };

  useEffect(() => {
    const job = props.jobs.find((item) => item.id === jobId);
    setTargetJob(job || null);
  }, [props.jobs, jobId]);

  const scrollToTarget = (target) => {
    scroller.scrollTo(target, {
      duration: 500,
      smooth: true,
      offset: 50, // Scrolls to element + 50 pixels down the page
    });
  };
  useEffect(() => {
    scrollToTarget("apply-job");
  }, []);
  return (
    <Element name="apply-job">
      <div className="apply">
        {targetJob ? (
          <>
            <div className="apply__titleContainer">
              <h2>{targetJob.title}</h2>
              <p>{targetJob.location}</p>
            </div>
            <div className="apply__jobDetails">
              <h2>{t("responsibilities")}</h2>
              <div dangerouslySetInnerHTML={{ __html: targetJob.responsibilities }} />
              <h2>{t("desiredSkills")}</h2>
              <div dangerouslySetInnerHTML={{ __html: targetJob.desiredskills }} />
            </div>
            <div className="apply__form">
              <h2>{t("beCandidate")}</h2>
              <TextField
                error={!form.first_name.valid}
                label={t("firstName")}
                helperText={!form.first_name.valid && t("invalidFirstName")}
                variant="standard"
                onBlur={() => {
                  checkForm("first_name");
                }}
                value={form.first_name.value}
                onChange={(event) => {
                  var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.first_name.max) {
                    value = value.slice(0, form.first_name.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      first_name: { ...prev.first_name, value: value },
                    };
                  });
                }}
              />
              <TextField
                error={!form.last_name.valid}
                label={t("lastName")}
                helperText={!form.last_name.valid && t("invalidLastName")}
                variant="standard"
                onBlur={() => {
                  checkForm("last_name");
                }}
                value={form.last_name.value}
                onChange={(event) => {
                  var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                  var value = event.target.value;
                  value = value.replace(format, "");
                  if (value?.length > form.last_name.max) {
                    value = value.slice(0, form.last_name.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      last_name: { ...prev.last_name, value: value },
                    };
                  });
                }}
              />
              <TextField
                error={!form.email.valid}
                label={t("email")}
                helperText={!form.email.valid && t("invalidEmail")}
                variant="standard"
                onBlur={() => {
                  checkForm("email");
                }}
                value={form.email.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.email.max) {
                    value = value.slice(0, form.email.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      email: { ...prev.email, value: value },
                    };
                  });
                }}
              />
              <MobilePhone form={form} setForm={setForm} />
              <TextField
                error={!form.city.valid}
                label={t("cityOfResidence")}
                helperText={!form.city.valid && t("invalidCity")}
                variant="standard"
                onBlur={() => {
                  checkForm("city");
                }}
                value={form.city.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.city.max) {
                    value = value.slice(0, form.city.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      city: { ...prev.city, value: value },
                    };
                  });
                }}
              />
              <TextField
                error={!form.why_luxpag.valid}
                label={t("whyLuxpag")}
                multiline
                maxRows={4}
                helperText={!form.why_luxpag.valid && t("requiredField")}
                variant="standard"
                onBlur={() => {
                  checkForm("why_luxpag");
                }}
                value={form.why_luxpag.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.why_luxpag.max) {
                    value = value.slice(0, form.why_luxpag.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      why_luxpag: { ...prev.why_luxpag, value: value },
                    };
                  });
                }}
              />
              <TextField
                error={!form.experience.valid}
                label={t("experience")}
                multiline
                maxRows={4}
                helperText={!form.experience.valid && t("requiredField")}
                variant="standard"
                onBlur={() => {
                  checkForm("experience");
                }}
                value={form.experience.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.experience.max) {
                    value = value.slice(0, form.experience.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      experience: { ...prev.experience, value: value },
                    };
                  });
                }}
              />
              <TextField
                error={!form.linkedin.valid}
                label={t("linkedin")}
                helperText={!form.linkedin.valid && t("invalidLinkedin")}
                variant="standard"
                onBlur={() => {
                  checkForm("linkedin");
                }}
                value={form.linkedin.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.linkedin.max) {
                    value = value.slice(0, form.linkedin.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      linkedin: { ...prev.linkedin, value: value },
                    };
                  });
                }}
              />
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.company_contact_status.valid}>
                <InputLabel id="demo-simple-select-standard-label">{t("compaynContact")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("compaynContact")}
                  onBlur={() => {
                    checkForm("company_contact_status");
                  }}
                  value={form.company_contact_status.value}
                  onChange={(event) => {
                    var value = event.target.value;
                    value === "Yes" && checkForm("company_contact");
                    setForm((prev) => {
                      return {
                        ...prev,
                        company_contact_status: { ...prev.company_contact_status, value: value },
                      };
                    });
                  }}
                >
                  <MenuItem value="Yes">{t("yes")}</MenuItem>
                  <MenuItem value="No">{t("no")}</MenuItem>
                </Select>
                {!form.company_contact_status.valid && <FormHelperText>{t("requiredField")}</FormHelperText>}
              </FormControl>
              <TextField
                error={!form.company_contact.valid}
                label={t("compaynContact2")}
                helperText={!form.company_contact.valid && t("requiredField")}
                variant="standard"
                onBlur={() => {
                  form.company_contact_status.value === "Yes" && checkForm("company_contact");
                }}
                value={form.company_contact.value}
                onChange={(event) => {
                  var value = event.target.value;
                  if (value?.length > form.company_contact.max) {
                    value = value.slice(0, form.company_contact.max);
                  }
                  setForm((prev) => {
                    return {
                      ...prev,
                      company_contact: { ...prev.company_contact, value: value },
                    };
                  });
                }}
              />
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.english_level.valid}>
                <InputLabel id="demo-simple-select-standard-label">{t("english")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("english")}
                  onBlur={() => {
                    checkForm("english_level");
                  }}
                  value={form.english_level.value}
                  onChange={(event) => {
                    var value = event.target.value;
                    setForm((prev) => {
                      return {
                        ...prev,
                        english_level: { ...prev.english_level, value: value },
                      };
                    });
                  }}
                >
                  <MenuItem value={englishLevel[0]}>{englishLevel[0]}</MenuItem>
                  <MenuItem value={englishLevel[1]}>{englishLevel[1]}</MenuItem>
                  <MenuItem value={englishLevel[2]}>{englishLevel[2]}</MenuItem>
                  <MenuItem value={englishLevel[3]}>{englishLevel[3]}</MenuItem>
                </Select>
                {!form.english_level.valid && <FormHelperText>{t("requiredField")}</FormHelperText>}
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.disability.valid}>
                <InputLabel id="demo-simple-select-standard-label">{t("disabilities")}</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={t("disabilities")}
                  onBlur={() => {
                    checkForm("disability");
                  }}
                  value={form.disability.value}
                  onChange={(event) => {
                    var value = event.target.value;
                    setForm((prev) => {
                      return {
                        ...prev,
                        disability: { ...prev.disability, value: value },
                      };
                    });
                  }}
                >
                  <MenuItem value="Yes">{t("yes")}</MenuItem>
                  <MenuItem value="No">{t("no")}</MenuItem>
                </Select>
                {!form.disability.valid && <FormHelperText>{t("requiredField")}</FormHelperText>}
              </FormControl>
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                variant="contained"
                className="apply__send"
                onClick={submitForm}
              >
                {t("send")}
              </LoadingButton>
            </div>
          </>
        ) : (
          <Box sx={{ width: "100%" }}>
            <LinearProgress className="apply__linearProgress" />
          </Box>
        )}
      </div>
    </Element>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    jobs: state.jobs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setDialog: (item) => dispatch(setDialog(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Apply);
