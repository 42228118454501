import React, { useState, useEffect } from "react";
import "./Contact.scss";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import countries from "../../utils/regionCodes";
import { Box, FormHelperText } from "@mui/material";
import MobilePhone from "../form-components/MobilePhone";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import { connect } from "react-redux";
import { getSign } from "../../utils/formats";
import { setDialog } from "../../redux/actions";
import { checkEmail, checkPhone } from "../../utils/validations";
import request from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// esaDecryptData, decrypt 
import { esaEncryptData, encrypt } from "../../utils/secret"
import { v4 as uuidv4 } from 'uuid'

function Contact(props) {
  const { t } = useTranslation();
  const countryCheckbox = {
    column1: [
      [t("brazil"), "BRA"],
      [t("colombia"), "COL"],
      [t("peru"), "PER"],
      [t("mexico"), "MEX"],
      [t("salvador"), "SLV"],
      [t("panama"), "PAN"],
      [t("paraguay"), "PRY"],
    ],
    column2: [
      [t("argentina"), "ARG"],
      [t("chile"), "CHL"],
      [t("ecuador"), "ECU"],
      [t("guatemala"), "GTM"],
      [t("uruguay"), "URY"],
      [t("china"), "CHN"],
      [t("japan"), "JPN"],
    ],
    column3: [
      [t("indonesia"), "IDN"],
      [t("philippines"), "PHL"],
      [t("korea"), "KOR"],
      [t("malaysia"), "MYS"],
      [t("thailand"), "THA"],
      [t("cambodia"), "KHM"],
      [t("other"), "other"],
    ],
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: { value: "", valid: true, max: 80, checked: false },
    email: { value: "", valid: true, max: 150, checked: false },
    phone: {
      value: "",
      valid: true,
      cCode: "",
      countryObj: "",
      checked: false,
    },
    company: { value: "", valid: true, max: 100, checked: false },
    company_website: { value: "", max: 300, valid: true, checked: false },
    company_size: { value: "", valid: true, checked: false },
    country: { value: "", valid: true, checked: false },
    industry: { value: "", valid: true, checked: false },
    global_transactions: { value: "", valid: true, checked: false },
    latam_transactions: { value: "", valid: true, checked: false },
    usage_countries: { value: [], valid: true, checked: false },
    solutions: { value: [], valid: true, checked: false },
    message: { value: "", valid: true, max: 1500, checked: false },
  });

  let [prefixUrl, setPrefixUrl] = useState('https://website-api.pagsmile.com')
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      setPrefixUrl('https://website-api-sandbox.pagsmile.com')
    }
  }, []);

  const handleError = () => {
    setIsLoading(false);
    props.setDialog({
      state: true,
      notice: {
        title: t("failed"),
        content: t("submit-error"),
      },
    });
  };

  const handleCheckbox = (event, target) => {
    const name = event.target.name;
    if (form[target].value?.includes?.(name)) {
      setForm((prev) => ({
        ...prev,
        [target]: { ...prev[target], value: prev[target].value.filter((item) => item !== name) },
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        [target]: { ...prev[target], value: [...prev[target].value, name] },
      }));
    }
  };

  const checkForm = (formKey) => {
    if (form[formKey]) {
      const value = form[formKey].value;
      const validity = Array.isArray(value) ? value.length !== 0 : !!value;
      setForm((prev) => ({ ...prev, [formKey]: { ...prev[formKey], valid: validity, checked: true } }));
      return validity;
    }
    return false;
  };

  const submitForm = () => {
    const validityFunctions = [
      checkForm("name"),
      checkEmail({ form: form, setForm: setForm }),
      checkPhone({ form: form, setForm: setForm }),
      checkForm("company"),
      checkForm("company_website"),
      checkForm("company_size"),
      checkForm("country"),
      checkForm("industry"),
      checkForm("global_transactions"),
      checkForm("latam_transactions"),
      checkForm("usage_countries"),
      checkForm("solutions"),
      checkForm("message"),
    ];
    const validity = validityFunctions.every((item) => item === true);
    if (validity) {
      const key = {
        name: form.name.value,
        area_code: form.phone.area_code,
        telephone: form.phone.value.replace(/\s/g, ""),
        email: form.email.value,
        company: form.company.value,
        company_website: form.company_website.value,
      };
      // const sign = getSign(key);
      const reqData = {
        ...key,
        company_size: form.company_size.value,
        country: form.country.value,
        industry: form.industry.value,
        global_transactions: form.global_transactions.value,
        latam_transactions: form.latam_transactions.value,
        usage_countries: form.usage_countries.value,
        solutions: form.solutions.value,
        message: form.message.value,
      };
      // console.log(JSON.stringify({ ...reqData, ...{ sign } }))
      const postData = {
        basic_info: {
          full_name: `${reqData.name} - from Luxpag.com`,
          phone_number: reqData.telephone,
          business_email: reqData.email,
          company_name: reqData.company,
          company_website: reqData.company_website,
          company_size: reqData.company_size,
          country: reqData.country,
          industry: reqData.industry,
          global_transaction_volume: reqData.global_transactions,
          latam_transaction_volume: reqData.latam_transactions,
          // info_sources: 'Google', // reqData.knowUs
          area_code: reqData.area_code
        },
        region: reqData.usage_countries,
        need_solution: reqData.solutions,
        demand: reqData.message,
        endpoint: 'luxpag'
      }
      const uuid = uuidv4().replace(/-/g, '')
      // const pagsmileUrl = `https://platform.pagsmile.com/dashboard/api/account/save-info`
      const pagsmileUrl = `${prefixUrl}/website/common/account/apply`
      const data = JSON.stringify({
        key: encrypt(uuid),
        data: esaEncryptData(JSON.stringify(postData), uuid)
      })
      setIsLoading(true);
      request({
        method: "POST",
        headers: { "content-type": "application/json" },
        url: pagsmileUrl,
        data: data
      })
        .then((res) => {
          // // 解密方式
          // const { data, key } = res
          // const result = esaDecryptData(data, decrypt(key))
          let response = res.data;
          if (Number(response.code) === 10000) {
            setIsLoading(false);
            props.setDialog({
              state: true,
              notice: {
                title: t("success"),
                content: t("submit-success"),
              },
              callback: () => {
                navigate("/");
              },
            });
          } else {
            handleError();
          }
        })
        .catch(() => {
          handleError();
        });
      // setIsLoading(true);
      // request({
      //   method: "POST",
      //   headers: { "content-type": "application/x-www-form-urlencoded" },
      //   url: "/index.php?r=api%2Fcontactus",
      //   data: `data=${encodeURIComponent(JSON.stringify({ ...reqData, ...{ sign } }))}`,
      // })
      //   .then((res) => {
      //     let response = res.data;
      //     if (Number(response.code) === 200) {
      //       setIsLoading(false);
      //       props.setDialog({
      //         state: true,
      //         notice: {
      //           title: t("success"),
      //           content: t("submit-success"),
      //         },
      //         callback: () => {
      //           navigate("/");
      //         },
      //       });
      //     } else {
      //       handleError();
      //     }
      //   })
      //   .catch(() => {
      //     handleError();
      //   });
    }
  };

  return (
    <div className="contact">
      <div className="contact__title">
        <h2>{t("completeTheFields2")}</h2>
      </div>
      <div className="contact__form">
        <div className="contact__form__sectionOne">
          <div className="contact__form__column">
            <TextField
              error={!form.name.valid}
              label={t("firstAndLastName")}
              helperText={!form.name.valid && t("invalidName")}
              variant="standard"
              onBlur={() => {
                checkForm("name");
              }}
              value={form.name.value}
              onChange={(event) => {
                var format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
                var value = event.target.value;
                value = value.replace(format, "");
                if (value?.length > form.name.max) {
                  value = value.slice(0, form.name.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    name: { ...prev.name, value: value },
                  };
                });
              }}
            />
            <MobilePhone form={form} setForm={setForm} />
            <TextField
              error={!form.email.valid}
              label={t("email")}
              helperText={!form.email.valid && t("invalidEmail")}
              variant="standard"
              onBlur={() => {
                checkForm("email");
              }}
              value={form.email.value}
              onChange={(event) => {
                var value = event.target.value;
                if (value?.length > form.email.max) {
                  value = value.slice(0, form.email.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    email: { ...prev.email, value: value },
                  };
                });
              }}
            />
            <TextField
              error={!form.company.valid}
              label={t("companyName")}
              helperText={!form.company.valid && t("invalidCompany")}
              variant="standard"
              onBlur={() => {
                checkForm("company");
              }}
              value={form.company.value}
              onChange={(event) => {
                var value = event.target.value;
                if (value?.length > form.company.max) {
                  value = value.slice(0, form.company.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    company: { ...prev.company, value: value },
                  };
                });
              }}
            />
            <TextField
              error={!form.company_website.valid}
              label={t("companyWebsite")}
              helperText={!form.company_website.valid && t("invalidWebsite")}
              variant="standard"
              onBlur={() => {
                checkForm("company_website");
              }}
              value={form.company_website.value}
              onChange={(event) => {
                var value = event.target.value;
                if (value?.length > form.company_website.max) {
                  value = value.slice(0, form.company_website.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    company_website: { ...prev.company_website, value: value },
                  };
                });
              }}
            />
          </div>
          <div className="contact__form__column">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.company_size.valid}>
              <InputLabel id="demo-simple-select-standard-label">{t("companySize")}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(event) => {
                  var value = event.target.value;
                  setForm((prev) => {
                    return {
                      ...prev,
                      company_size: { ...prev.company_size, value: value },
                    };
                  });
                }}
                label={t("companySize")}
                onBlur={() => {
                  checkForm("company_size");
                }}
              >
                <MenuItem disabled value="">
                  Number of employees
                </MenuItem>
                <MenuItem value="1-10">1-10</MenuItem>
                <MenuItem value="11-50">11-50</MenuItem>
                <MenuItem value="51-100">51-100</MenuItem>
                <MenuItem value="101-1000">101-1000</MenuItem>
                <MenuItem value="1000-More">1000+</MenuItem>
              </Select>
              {!form.company_size.valid && <FormHelperText>{t("invalidSize")}</FormHelperText>}
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.country.valid}>
              <InputLabel id="demo-simple-select-standard-label">{t("country")}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(event) => {
                  var value = event.target.value;
                  setForm((prev) => {
                    return {
                      ...prev,
                      country: { ...prev.country, value: value },
                    };
                  });
                }}
                label={t("country")}
                onBlur={() => {
                  checkForm("country");
                }}
              >
                {countries.map((item) => (
                  <MenuItem value={item.alpha3_code}>{item.country_name}</MenuItem>
                ))}
              </Select>
              {!form.country.valid && <FormHelperText>{t("invalidCountry")}</FormHelperText>}
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.industry.valid}>
              <InputLabel id="demo-simple-select-standard-label">{t("industry")}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(event) => {
                  var value = event.target.value;
                  setForm((prev) => {
                    return {
                      ...prev,
                      industry: { ...prev.industry, value: value },
                    };
                  });
                }}
                label={t("industry")}
                onBlur={() => {
                  checkForm("industry");
                }}
              >
                <MenuItem value="1">Digital Content</MenuItem>
                <MenuItem value="2">E-Commerce</MenuItem>
                <MenuItem value="3">FX</MenuItem>
                <MenuItem value="4">Game</MenuItem>
                <MenuItem value="5">IT Technical Service</MenuItem>
                <MenuItem value="6">Logistics</MenuItem>
                <MenuItem value="7">Marketing</MenuItem>
                <MenuItem value="8">Service</MenuItem>
                <MenuItem value="9">Securities Dealer</MenuItem>
                <MenuItem value="10">PSP (Internal)</MenuItem>
                <MenuItem value="11">PSP</MenuItem>
                <MenuItem value="12">Others</MenuItem>
                <MenuItem value="13">Social</MenuItem>
              </Select>
              {!form.industry.valid && <FormHelperText>{t("invalidIndustry")}</FormHelperText>}
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.global_transactions.valid}>
              <InputLabel id="demo-simple-select-standard-label">{t("whatIsCompany")}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(event) => {
                  var value = event.target.value;
                  setForm((prev) => {
                    return {
                      ...prev,
                      global_transactions: { ...prev.global_transactions, value: value },
                    };
                  });
                }}
                label={t("whatIsCompany")}
                onBlur={() => {
                  checkForm("global_transactions");
                }}
              >
                <MenuItem value="$0-$500k">$0 - $500k USD</MenuItem>
                <MenuItem value="$500k-$999k">$500k - $999k USD</MenuItem>
                <MenuItem value="$1M-$9M">$1M - $9M USD</MenuItem>
                <MenuItem value="$10M-$50M">$10M - $50M USD</MenuItem>
                <MenuItem value="$51M-$100M">$51M - $100M USD</MenuItem>
                <MenuItem value="$100M-More">$100M+ USD</MenuItem>
              </Select>
              {!form.global_transactions.valid && <FormHelperText>{t("invalidTrxVolume")}</FormHelperText>}
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={!form.latam_transactions.valid}>
              <InputLabel id="demo-simple-select-standard-label">{t("whatIsCompany2")}</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(event) => {
                  var value = event.target.value;
                  setForm((prev) => {
                    return {
                      ...prev,
                      latam_transactions: { ...prev.latam_transactions, value: value },
                    };
                  });
                }}
                label={t("whatIsCompany2")}
                onBlur={() => {
                  checkForm("latam_transactions");
                }}
              >
                <MenuItem value="$0-$500k">$0 - $500k USD</MenuItem>
                <MenuItem value="$500k-$999k">$500k - $999k USD</MenuItem>
                <MenuItem value="$1M-$9M">$1M - $9M USD</MenuItem>
                <MenuItem value="$10M-$50M">$10M - $50M USD</MenuItem>
                <MenuItem value="$51M-$100M">$51M - $100M USD</MenuItem>
                <MenuItem value="$100M-More">$100M+ USD</MenuItem>
              </Select>
              {!form.latam_transactions.valid && <FormHelperText>{t("invalidTrxVolume")}</FormHelperText>}
            </FormControl>
          </div>
        </div>
        <div className="contact__form__sectionTwo">
          <FormControl
            fullWidth
            error={!form.usage_countries.valid}
            component="fieldset"
            variant="standard"
            sx={{ mt: 3 }}
          >
            <FormLabel component="legend">{t("whichCountry")}</FormLabel>
            <FormGroup>
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                <div className="contact__form__column" style={{ width: "32%" }}>
                  {countryCheckbox.column1.map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            handleCheckbox(event, "usage_countries");
                          }}
                          name={item[1]}
                        />
                      }
                      label={item[0]}
                    />
                  ))}
                </div>
                <div className="contact__form__column" style={{ width: "32%" }}>
                  {countryCheckbox.column2.map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            handleCheckbox(event, "usage_countries");
                          }}
                          name={item[1]}
                        />
                      }
                      label={item[0]}
                    />
                  ))}
                </div>
                <div className="contact__form__column" style={{ width: "32%" }}>
                  {countryCheckbox.column3.map((item) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            handleCheckbox(event, "usage_countries");
                          }}
                          name={item[1]}
                        />
                      }
                      label={item[0]}
                    />
                  ))}
                </div>
              </div>
            </FormGroup>
            {!form.usage_countries.valid && <FormHelperText>{t("selectCountry")}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth error={!form.solutions.valid} component="fieldset" variant="standard" sx={{ mt: 3 }}>
            <FormLabel component="legend">{t("whichSolution")}</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleCheckbox(event, "solutions");
                    }}
                    name="1"
                  />
                }
                label="Payins - Accept local payment methods."
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleCheckbox(event, "solutions");
                    }}
                    name="2"
                  />
                }
                label="Payouts - Orchestrate local payouts."
              />
            </FormGroup>
            {!form.solutions.valid && <FormHelperText>{t("selectSolution")}</FormHelperText>}
          </FormControl>

          <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">{t("howLuxpag")}</FormLabel>
            <TextField
              error={!form.message.valid}
              multiline
              rows={4}
              helperText={!form.message.valid && t("completeField")}
              variant="outlined"
              onBlur={() => {
                checkForm("message");
              }}
              value={form.message.value}
              onChange={(event) => {
                var value = event.target.value;
                if (value?.length > form.message.max) {
                  value = value.slice(0, form.message.max);
                }
                setForm((prev) => {
                  return {
                    ...prev,
                    message: { ...prev.message, value: value },
                  };
                });
              }}
            />
          </Box>
        </div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton
            onClick={submitForm}
            loading={isLoading}
            loadingPosition="start"
            variant="contained"
            className="contact__form__send"
          >
            {t("send")}
          </LoadingButton>
        </Box>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return { setDialog: (item) => dispatch(setDialog(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
