import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer__contact">
        <div className="footer--column">
          {/* <p>+ 52 (11) 2189349821</p> */}
          <p>support@luxpag.com</p>
          <Link to="contact">
            <span>{t("contactUs")}</span>
          </Link>
        </div>
      </div>
      <div className="footer__navs">
        {/* <div className="footer--column">
          <h2>{t("solutions")}</h2>
        </div> */}
        <div className="footer--column">
          <h2>{t("developers")}</h2>
          <p
            onClick={() => {
              window.open("https://developer.luxpag.com/", "_blank");
            }}
            style={{ cursor: "pointer" }}
          >
            {t("Documentation")}
          </p>
        </div>
        <div className="footer--column">
          <h2> {t("legal")}</h2>
          <Link to="legal">{t("termsAndConditions")}</Link>
          <Link to="legal">{t("privacyPolicy")}</Link>
          <Link to="legal">{t("restrictedActivities")}</Link>
        </div>
        {/* <div className="footer--column">
          <h2>{t("help")}</h2>
          <Link>{t("helpCenter")}</Link>
        </div> */}
        <div className="footer--column">
          <h2>{t("aboutUs")}</h2>
          <Link>{t("company")}</Link>
          <Link to="career">{t("careers")}</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
