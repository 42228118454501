import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import Flags from "../../img/flags.png";
import Autocomplete from "@mui/material/Autocomplete";
import countryList from "../../utils/regionCodes";
import "./MobilePhone.scss";
import { formatPhone } from "../../utils/formats";
import { checkPhone, checkIsPhoneValid } from "../../utils/validations";
import { AsYouType } from "libphonenumber-js/mobile";
var classNames = require("classnames");

function MobilePhone({ form, setForm, mobile, ...props }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [dropDown, setDropDown] = useState(false);
  const defaultCountryObj = {
    country_name: "United States",
    alpha2_code: "US",
    alpha3_code: "USA",
    phone_code: "+1",
    phone_num: ""
  };

  useEffect(() => {
    if (!form.phone.checked) {
      const obj = defaultCountryObj;
      setForm((prev) => {
        return {
          ...prev,
          phone: {
            ...prev.phone,
            countryObj: obj,
            area_code: obj.phone_code,
            value: obj.phone_num,
            valid: true,
            checked: false,
          },
        };
      });
    }
  }, []);

  document.body.onclick = () => {
    setDropDown(false);
  };

  return (
    <div className="mobilePhone">
      <TextField
        onFocus={() => {
          setDropDown(false);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ inputMode: "numeric", autocomplete: "new-password", "ga-data": "340" }}
        error={!form.phone.valid}
        helperText={!form.phone.valid && t("invalidPhone")}
        label={t("telephone")}
        style={{ width: "100%" }}
        variant="standard"
        value={`${form.phone.area_code}${form.phone.value}`}
        onChange={(event) => {
          let value = event.target.value;
          if (value?.length > 20) {
            value = value.slice(0, 20);
          }
          if (!value?.startsWith(form.phone.countryObj.phone_code)) {
            value = form.phone.countryObj.phone_code;
          }
          value = formatPhone(value, form.phone.countryObj.alpha2_code);
          setForm((prev) => {
            return {
              ...prev,
              phone: { ...prev.phone, value: value.replace(form.phone.area_code, "") },
            };
          });
        }}
        onBlur={() => {
          checkPhone({ form: form, setForm: setForm, region: form.phone.countryObj.alpha2_code });
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div
                className="mobilePhone__flagContainer"
                onClick={() => {
                  setDropDown((prev) => !prev);
                }}
              >
                <div
                  style={{ backgroundImage: `url(${Flags})` }}
                  className={classNames("mobilePhone__flag", {
                    [form.phone.countryObj?.alpha2_code?.toLowerCase?.()]: form.phone.countryObj?.alpha2_code,
                  })}
                ></div>
                <div className={classNames("mobilePhone__arow", { "arrow--up": dropDown })}></div>
              </div>
            </InputAdornment>
          ),
        }}
      />
      <div
        className="mobilePhone__countrySelect"
        style={{ display: dropDown ? "block" : "none" }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Autocomplete
          value={form.phone.countryObj}
          onChange={(event, newValue) => {
            newValue &&
              setForm((prev) => {
                return {
                  ...prev,
                  phone: {
                    ...prev.phone,
                    countryObj: newValue,
                    area_code: newValue.phone_code,
                    value: "",
                    valid: true,
                    checked: false,
                  },
                };
              });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={countryList}
          getOptionLabel={(option) => option.country_name}
          renderOption={(props, option) => (
            <div style={{ display: "flex", alignItems: "center" }} {...props}>
              <div
                style={{ backgroundImage: `url(${Flags})`, marginRight: "10px", marginBottom: "5px" }}
                className={`mobilePhone__flag ${option.alpha2_code?.toLowerCase?.()}`}
              ></div>
              {option.country_name} {option.phone_code}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Search"}
              variant="outlined"
              // error={!form.state.valid}
              // helperText={!form.state.valid && t("invalid_state")}
              size="small"
              inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
            />
          )}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onBlur={() => {
            // checkState({ form: form, setForm: setForm });
          }}
        />
      </div>
    </div>
  );
}

export default MobilePhone;
