import React from "react";
import "./PaymentMethods.scss";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Pix from "../img/pix_logo.svg";
import Alipay from "../img/alipay_logo.svg";
import Efecty from "../img/efecty_logo.svg";
import UPI from "../img/upi_logo.svg";
import { useTranslation } from "react-i18next";

const MediaCard = ({ title, content, image }) => {
  return (
    <Card sx={{ width: 320, height: 320, margin: "10px" }}>
      <div style={{ padding: "16px 16px 0" }}>
        <div
          style={{
            height: "30px",
            width: "30px",
            padding: "10px",
            backgroundColor: image.background,
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={image.img} alt="" />
        </div>
      </div>
      <CardContent style={{ padding: "16px" }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

function PaymentMethods() {
  const { t } = useTranslation();
  const paymentMethods = {
    column1: [
      {
        title: "Pix",
        content: t("paymentForProd"),
        image: {
          img: Pix,
          background: "#D5FFFA",
        },
      },
      {
        title: "Efecty",
        content: t("efectyIsA"),
        image: {
          img: Efecty,
          background: "#FBEA24",
        },
      },
    ],

    column2: [
      {
        title: "Alipay",
        content: t("alipayOvertook"),
        image: {
          img: Alipay,
          background: "#DEF4FF",
        },
      },
      {
        title: "UPI",
        content: t("unifiedPayments"),
        image: {
          img: UPI,
          background: "#FFD4BC",
        },
      },
    ],
  };
  return (
    <div className="paymentMethods">
      <div className="paymentMethods__text">
        <h2>{t("ourPopular")}</h2>
        <ul>
          <li>{t("integrateDirectly")}</li>
          <li>{t("over200")}</li>
          <li>{t("oneSingle")}</li>
        </ul>
      </div>
      <div className="paymentMethods__cards">
        <div className="paymentMethods--column1">
          {paymentMethods.column1.map((item) => (
            <MediaCard title={item.title} content={item.content} image={item.image} />
          ))}
        </div>
        <div className="paymentMethods--column2">
          {paymentMethods.column2.map((item) => (
            <MediaCard title={item.title} content={item.content} image={item.image} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PaymentMethods;
