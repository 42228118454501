import { SET_JOBS, SET_DIALOG } from "./types";

const initialState = {
  jobs: [],
  dialog: { state: false, notice: { title: "", content: "" }, callback: () => {} },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JOBS:
      return {
        ...state,
        jobs: action.item,
      };
    case SET_DIALOG:
      return {
        ...state,
        dialog: action.item,
      };
    default:
      return state;
  }
};

export default reducer;
