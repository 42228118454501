import React, { useEffect, lazy, Suspense } from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Career from "./components/pages/Career/Career";
import Apply from "./components/pages/Career/Apply/Apply";
import NotFound from "./components/pages/NotFound";
import Jobjs from "./components/pages/Career/Jobs/Jobjs";
// import LivechatWidget from "./components/LivechatWidget";
import { connect } from "react-redux";
import { getJobs, setDialog } from "./redux/actions";
import ScrollToTop from "./components/ScrollToTop";
import Account from "./components/pages/Contact";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Legal from "./components/pages/Legal";

function App(props) {
  const { t } = useTranslation();
  // console.log(">>>>>>> app");
  useEffect(() => {
    props.getJobs();
  }, []);

  return (
    <div className="App">
      <Dialog
        open={props.dialog?.state}
        disableEscapeKeyDown
        onClose={() => {
          props.setDialog({ state: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="App___dialog"
      >
        <DialogTitle id="alert-dialog-title">{props.dialog?.notice?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.dialog?.notice?.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.dialog.callback && props.dialog?.callback?.();
              props.setDialog({ state: false });
            }}
            autoFocus
          >
            {t("okay")}
          </Button>
        </DialogActions>
      </Dialog>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="career" element={<Career />}>
            <Route path="" element={<Jobjs />} />
            <Route path="apply/:jobId" element={<Apply />} />
          </Route>
          <Route path="contact" element={<Account />} />
          <Route path="legal" element={<Legal />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      <Footer />
      {/* <LivechatWidget /> */}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    jobs: state.jobs,
    dialog: state.dialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobs: () => dispatch(getJobs()),
    setDialog: (item) => dispatch(setDialog(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
