import React from "react";
import "./Features.scss";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Group1 from "../img/Group1.svg";
import Group2 from "../img/Group2.svg";
import Group3 from "../img/Group3.svg";
import Group4 from "../img/Group4.svg";
import Group5 from "../img/Group5.svg";
import Group6 from "../img/Group6.svg";
import { useTranslation } from "react-i18next";

const MediaCard = ({ title, content, image }) => {
  return (
    <Card sx={{ width: 320, height: 230 }}>
      <div style={{ padding: "16px 16px 0" }}>
        <div
          style={{
            height: "60px",
            width: "60px",
            padding: "10px",
            backgroundColor: "#1D1D1D",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={image} alt="" />
        </div>
      </div>
      <CardContent style={{ padding: "16px" }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

function Features() {
  const { t } = useTranslation();
  const features = {
    row1: [
      {
        title: t("crossBorder"),
        content: t("upgradeYour"),
        image: Group1,
      },
      { title: t("emerging"), content: t("tapInfo"), image: Group2 },
      { title: t("Speed"), content: t("serveYour"), image: Group3 },
    ],

    row2: [
      {
        title: t("reliability"),
        content: t("yourTransactions"),
        image: Group4,
      },
      {
        title: t("safe"),
        content: t("frontEndScript"),
        image: Group5,
      },
      {
        title: t("localPayments"),
        content: t("methodsThat"),
        image: Group6,
      },
    ],
  };
  return (
    <div className="features">
      <h2 className="features__title">{t("feature")}</h2>
      <div className="features__row1">
        {features.row1.map((item) => (
          <MediaCard title={item.title} content={item.content} image={item.image} />
        ))}
      </div>
      <div className="features__row2">
        {features.row2.map((item) => (
          <MediaCard title={item.title} content={item.content} image={item.image} />
        ))}
      </div>
    </div>
  );
}

export default Features;
