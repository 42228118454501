import React, { useState, useEffect } from "react";
import "./Jobs.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import JobGroup from "../../../JobGroup";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const groupJobs = (jobs = []) => {
  let grouped = {};
  jobs?.forEach?.((item) => {
    grouped = { ...grouped, [item.class_name]: [...(grouped[item.class_name] || []), item] };
  });
  return Object.values(grouped);
};

function Jobjs(props) {
  const { t } = useTranslation();
  const [groupedJobs, setGroupedJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [jobTitle, setJobTitle] = React.useState("All");
  const [jobLocation, setJobLocation] = React.useState("All");

  useEffect(() => {
    setGroupedJobs(groupJobs(selectedJobs));
  }, [selectedJobs]);

  useEffect(() => {
    const filteredJobs = props.jobs.filter(
      (item) =>
        (jobTitle === "All" || item.title === jobTitle) && (jobLocation === "All" || item.location === jobLocation)
    );

    setSelectedJobs(filteredJobs || []);
  }, [jobTitle, jobLocation, props.jobs]);
  return (
    <div className="career__jobs">
      <div className="career__jobsSelection">
        <FormControl>
          <InputLabel id="demo-simple-select-label">{t("chooseDepart")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={jobTitle}
            label="Choose department"
            onChange={(event) => {
              setJobTitle(event.target.value);
            }}
          >
            <MenuItem value="All">All</MenuItem>
            {[
              ...new Set(
                props.jobs
                  .filter((item) => jobLocation === "All" || item.location === jobLocation)
                  .map((item) => item.title)
              ),
            ].map((title) => (
              <MenuItem value={title} key={title}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="demo-simple-select-label">{t("chooseLocation")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={jobLocation}
            label="Choose location"
            onChange={(event) => {
              setJobLocation(event.target.value);
            }}
          >
            <MenuItem value="All">All</MenuItem>
            {[
              ...new Set(
                props.jobs.filter((item) => jobTitle === "All" || item.title === jobTitle).map((item) => item.location)
              ),
            ].map((location) => (
              <MenuItem value={location} key={location}>
                {location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="career__jobsList">{groupedJobs && groupedJobs.map((item) => <JobGroup jobs={item} />)}</div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    jobs: state.jobs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Jobjs);
