import React from "react";
import "./JobGroup.scss";
import JobLogo from "../img/headset_icon.svg";
import { useNavigate } from "react-router-dom";

function JobGroup({ jobs = {} }) {
  const navigate = useNavigate();
  return (
    <div className="jobGroup">
      <h2 className="jobGroup__title">{jobs[0]?.class_name || "XXXXXXXXXX"}</h2>
      <div className="jobGroup__jobsContainer">
        {jobs.map((item) => (
          <div
            className="jobGroup__job"
            onClick={() => {
              // console.log("clicked >>>>");
              navigate(`apply/${item.id}`);
            }}
          >
            <img src={JobLogo} alt="" />
            <h3>{item.title}</h3>
            <p>{item.location}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default JobGroup;
