import React from "react";
import { useTranslation } from "react-i18next";
function PrivacyPolicy() {
  const { t } = useTranslation();
  console.log(t("restricted.items", { returnObjects: true }));
  return (
    <div>
      <h2>{t("restricted.title")}</h2>
      {t("restricted.text", { returnObjects: true })?.map?.((item) => (
        item.includes("•") ? <p>&nbsp;&nbsp;&nbsp;&nbsp;{item}</p> : <p>{item}</p>
      ))}

      {t("restricted.items", { returnObjects: true })?.map?.((item) => (
        <>
          <h3>{item.title}</h3>
          {item.text?.map?.((item) => (
            item.includes("•") ? <p>&nbsp;&nbsp;&nbsp;&nbsp;{item}</p> : <p>{item}</p>
          ))}
        </>
      ))}
    </div>
  );
}

export default PrivacyPolicy;
