import { AsYouType } from "libphonenumber-js";
import md5 from 'md5'

export const formatPhone = (number, code) => {
  code = String(code ?? "").toUpperCase();

  // By default, if a value is something like `"(123)"`
  // then Backspace would only erase the rightmost brace
  // becoming something like `"(123"`
  // which would give the same `"123"` value
  // which would then be formatted back to `"(123)"`
  // and so a user wouldn't be able to erase the phone number.
  // Working around this issue with this simple hack.

  if (number.length <= 4) {
    return number;
  } else {
    const asYouType = new AsYouType(code);
    // asYouType.input(number);
    // console.log("Int number: ", asYouType?.getNumber());
    // return asYouType?.getNumber()?.number ?? number;
    return asYouType.input(number) || "";
  }
};

const ksort = (obj) => {
  const keys = Object.keys(obj).sort();
  const sortedObj = {};
  for (const i in keys) {
    sortedObj[keys[i]] = obj[keys[i]];
  }
  return sortedObj;
};

export const getSign = (obj) => {
  const sortedObj = ksort(obj);
  let str = "";
  const keys = Object.keys(sortedObj);
  keys.forEach((key) => {
    str = str.concat(key, "=", sortedObj[key], "&");
  });
  const privatekey = "cd7fe6a9d92b2f4c17b363499ce589f5";
  str = str.concat(privatekey);
  const sign = md5(md5(str).toString()).toString();
  return sign;
};
