import React, { useEffect, useState } from "react";
import "./Header.scss";
import LuxpagLogo from "../img/Logo.svg";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const ContactWithLang = ({ target = "both" }) => {
  const { t, i18n } = useTranslation();
  const [anchorElLang, setAnchorElLang] = useState(null);

  const handleClickLang = (event) => {
    event.stopPropagation();
    setAnchorElLang(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElLang(null);
  };
  const handleChangeLang = (event) => {
    i18n.changeLanguage(event.target.getAttribute("name"));
    setAnchorElLang(null);
  };
  return (
    <div className="header__contactWithLang">
      {(target === "both" || target === "lang") && (
        <>
          <Button
            id="basic-button"
            variant="outlined"
            aria-controls={!!anchorElLang ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={!!anchorElLang ? "true" : undefined}
            onClick={handleClickLang}
            endIcon={<KeyboardArrowDownIcon />}
            className="header__lang"
          >
            {t(i18n.resolvedLanguage)}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorElLang}
            open={!!anchorElLang}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={handleChangeLang} name="en">
              {t("en")}
            </MenuItem>
            <MenuItem onClick={handleChangeLang} name="es">
              {t("es")}
            </MenuItem>
            <MenuItem onClick={handleChangeLang} name="pt">
              {t("pt")}
            </MenuItem>
          </Menu>
        </>
      )}
      {(target === "both" || target === "contact") && (
        <Link to="contact">
          <Button variant="contained" className="header__contact">
            {t("startNow")}
          </Button>
        </Link>
      )}
    </div>
  );
};

function Header() {
  const { t } = useTranslation();

  const [drawerState, setDrawerState] = React.useState(false);

  const toggleDrawer = (state) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerState(state);
  };

  const [isHome, setIsHome] = useState(false);
  const [isBackGroundColor, setIsBackGroundColor] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const controlHeader = () => {
    if (window.scrollY > 100) {
      setIsBackGroundColor(true);
    } else {
      setIsBackGroundColor(false);
    }
  };
  useEffect(() => {
    if (location.pathname === "/") {
      setIsHome(true);
      setIsBackGroundColor(false);
      window.addEventListener("scroll", controlHeader);
      return () => {
        window.removeEventListener("scroll", controlHeader);
        setIsBackGroundColor(false);
      };
    } else {
      setIsHome(false);
      setIsBackGroundColor(true);
    }
  }, [location.pathname]);
  return (
    <>
      <div className={`header ${!isBackGroundColor && "header--nobg"} ${isHome && "header--fixed"}`}>
        <div className="header__logo">
          <img
            src={LuxpagLogo}
            alt=""
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="header__navbar">
          <Link to="/" state="features">
            {t("feature")}
          </Link>
          <Link to="/" state="payment-methods">
            {t("paymentMethod")}
          </Link>
          <Link
            onClick={() => {
              window.open("https://developer.luxpag.com/", "_blank");
            }}
          >
            {t("developers")}
          </Link>
          <Link to="career">{t("carreer")}</Link>
        </div>
        <ContactWithLang target="both" />
        {/* <div className="header__start">
          <Link to="contact">
            <Button variant="contained">Start Now</Button>
          </Link>
        </div> */}
      </div>
      <div className="headerMobile">
        {/* <AppBar position="static" sx={{ bgcolor: "#000000" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
            <Box sx={{ display: { xs: "flex", md: "none", alignItems: "center" } }}>
              <img src={LuxpagLogo} className="header__logo" alt="" />
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon style={{ fontSize: "50px" }} />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none", color: "#000000" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/" state="features" style={{ textDecoration: "none", color: "#000000" }}>
                    <Typography textAlign="center">{t("feature")}</Typography>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/" state="payment-methods" style={{ textDecoration: "none", color: "#000000" }}>
                    <Typography textAlign="center">{t("paymentMethod")}</Typography>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    onClick={() => {
                      window.open("https://developer.luxpag.com/", "_blank");
                    }}
                  >
                    {t("developers")}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    onClick={() => {
                      navigate("career");
                    }}
                  >
                    {t("carreer")}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>

            <ContactWithLang />
          </Toolbar>
        </AppBar> */}
        <Box sx={{ display: { xs: "flex", md: "none", alignItems: "center" } }}>
          <img
            src={LuxpagLogo}
            className="header__logo"
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          />
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon style={{ fontSize: "50px", color: "#fff" }} />
          </IconButton>
          <SwipeableDrawer
            anchor="left"
            PaperProps={{ sx: { backgroundColor: "rgb(21,21,21)", color: "#fff" } }}
            open={drawerState}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              <List>
                <Box sx={{ display: { xs: "flex", justifyContent: "space-between", alignItems: "center" }, p: 1 }}>
                  <img
                    src={LuxpagLogo}
                    alt=""
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <ContactWithLang target="lang" />
                </Box>

                <Divider sx={{ borderColor: "rgb(182 182 182 / 32%)" }} />

                <ListItem
                  disablePadding
                  onClick={() => {
                    navigate("/", { state: "features" });
                  }}
                >
                  <ListItemButton>
                    <ListItemText primary={t("feature")} />
                  </ListItemButton>
                </ListItem>

                <Divider sx={{ borderColor: "rgb(182 182 182 / 32%)" }} />
                <ListItem
                  disablePadding
                  onClick={() => {
                    navigate("/", { state: "payment-methods" });
                  }}
                >
                  <ListItemButton>
                    <ListItemText primary={t("paymentMethod")} />
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ borderColor: "rgb(182 182 182 / 32%)" }} />
                <ListItem
                  disablePadding
                  onClick={() => {
                    window.open("https://developer.luxpag.com/", "_blank");
                  }}
                >
                  <ListItemButton>
                    <ListItemText primary={t("developers")} />
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ borderColor: "rgb(182 182 182 / 32%)" }} />
                <ListItem
                  disablePadding
                  onClick={() => {
                    navigate("career");
                  }}
                >
                  <ListItemButton>
                    <ListItemText primary={t("carreer")} />
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ borderColor: "rgb(182 182 182 / 32%)" }} />
              </List>
            </Box>
          </SwipeableDrawer>
        </Box>
        <ContactWithLang target="contact" />
      </div>
    </>
  );
}

export default Header;
